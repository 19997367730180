/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"

import { CarouselContext } from "./common/context"
import { CarouselPagination, CarouselTrigger } from "./common/pagination"
import { CarouselNavigationButton } from "./common/controls"
import { useCarouselNavigation } from "./common/hooks"

// Custom navigation component instead using the one from ./common/controls.
const CarouselNavigation = () => {
  const {
    onClickNext,
    onClickPrevious,
    showNext,
    showPrevious,
  } = useCarouselNavigation()

  return (
    <React.Fragment>
      {showPrevious && (
        <CarouselNavigationButton
          customStyle={{ left: 2 }}
          onClick={onClickPrevious}
        >
          {"<"}
        </CarouselNavigationButton>
      )}

      {showNext && (
        <CarouselNavigationButton
          customStyle={{ right: 2 }}
          onClick={onClickNext}
        >
          {">"}
        </CarouselNavigationButton>
      )}
    </React.Fragment>
  )
}

const CarouselSecondary = ({ children }) => {
  const { activeIndex, setActiveIndex } = React.useContext(CarouselContext)

  return (
    <div sx={{ position: "relative" }}>
      <SwipeableViews
        enableMouseEvents
        index={activeIndex}
        onChangeIndex={setActiveIndex}
      >
        {children}
      </SwipeableViews>

      <CarouselPagination
        containerStyle={{
          bottom: 1,
          left: "50%",
          position: "absolute",
          transform: "translateX(-50%)",
        }}
        triggerStyle={{
          mr: 1,
          mb: 0,
          ":last-child": { mr: 0 },
        }}
        trigger={CarouselTrigger}
      />

      <CarouselNavigation />
    </div>
  )
}

CarouselSecondary.propTypes = { children: PropTypes.node.isRequired }

export default CarouselSecondary
