import React from "react"

import { CarouselContext } from "./context"

export function useCarouselNavigation() {
  const { activeIndex, setActiveIndex, totalSlides } = React.useContext(
    CarouselContext
  )
  const handlePreviousSlide = React.useCallback(() => {
    if (activeIndex) {
      setActiveIndex(currentActiveIndex => currentActiveIndex - 1)
    }
  }, [setActiveIndex, activeIndex])

  const handleNextSlide = React.useCallback(() => {
    if (activeIndex < totalSlides - 1) {
      setActiveIndex(currentActiveIndex => currentActiveIndex + 1)
    }
  }, [setActiveIndex, activeIndex, totalSlides])

  return React.useMemo(
    () => ({
      onClickNext: handleNextSlide,
      onClickPrevious: handlePreviousSlide,
      showNext: activeIndex < totalSlides - 1,
      showPrevious: activeIndex > 0,
    }),
    [handlePreviousSlide, handleNextSlide, activeIndex, totalSlides]
  )
}
