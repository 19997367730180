/** @jsx jsx */
import get from "lodash/get"
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"
import { Flex } from "@theme-ui/components"

import { mediaQueryList, renderLineBreak } from "../helpers"

import Column from "../components/column"
import Container from "../components/container"
import CTA from "./cta"
import Row from "../components/row"
import Text from "./text"

const Hero = ({
  background,
  body,
  ctaList,
  customStyle,
  horizontalAlign,
  tagline,
  title,
  type,
  verticalAlign,
  textWidth,
}) => {
  const getVerticalAlignment = () => {
    const verticalAlignList = {
      bottom: "flex-end",
      center: "center",
    }

    return verticalAlignList[verticalAlign] || null
  }
  const renderMedia = () => {
    let mediaType = null

    if (background) {
      const mediaTypeList = {
        image: () => {
          const imageStyle = {
            display: "block",
            height: type && type === "secondary" ? [220, 220, "100%"] : "100%",
            left: 0,
            m: 0,
            objectFit: "cover",
            position:
              type === "secondary"
                ? ["relative", "relative", "absolute"]
                : "absolute",
            top: 0,
            width: "100%",
          }

          return (
            <picture>
              {get(background, "source.mobileImage.file.url") ? (
                <>
                  <source
                    media={`${mediaQueryList()[1]}`}
                    srcSet={`${background.source.desktopImage.file.url}`}
                  />
                  <img
                    sx={imageStyle}
                    src={`${background.source.mobileImage.file.url}`}
                    alt={`${background.source.mobileImage.title}`}
                  />
                </>
              ) : get(background, "source.desktopImage.file.url") ? (
                <img
                  sx={imageStyle}
                  src={`${background.source.desktopImage.file.url}`}
                  alt={`${background.source.desktopImage.file.title}`}
                />
              ) : null}
            </picture>
          )
        },
        video: () => (
          <div
            sx={{
              height: "100%",
              left: 0,
              overflow: "hidden",
              position: "absolute",
              top: 0,
              width: "100%",
            }}
          >
            <video
              autoPlay
              muted
              loop
              sx={{
                minHeight: "100%",
                minWidth: "100%",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              poster={background.source.poster.file.url}
            >
              <source src={background.source.video.file.url} />
            </video>
          </div>
        ),
      }

      mediaType = background.type
        ? mediaTypeList[background.type.split("/")[0]]()
        : mediaTypeList.image()
    }

    return mediaType
  }
  const heroStyle = {
    flexDirection: "column",
    justifyContent:
      type && type === "secondary"
        ? [null, null, getVerticalAlignment()]
        : getVerticalAlignment(),
    minHeight: type && type === "secondary" ? [null, null, 568] : 568,
    position: "relative",
    ...(type &&
      type === "primary" && {
        height: theme => `calc(100vh - ${theme.sizes.header.height}px)`,
      }),
  }

  const heroTextPosition =
    type === "primary"
      ? {
          position: ["absolute", "absolute", "relative"],
          top: 0,
          maxWidth: ["75%", "75%", "none"],
        }
      : { position: "relative" }

  return (
    <Flex sx={{ ...heroStyle, ...customStyle }}>
      {renderMedia()}
      {(body || ctaList || tagline || title) && (
        <Container>
          <Row>
            <Column
              customStyle={{
                py:
                  type && type === "secondary"
                    ? theme => [
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.sm,
                        6,
                      ]
                    : 6,
                ...heroTextPosition,
                textAlign: horizontalAlign || null,
              }}
            >
              {tagline && (
                <Text
                  customStyle={{
                    color:
                      type === "secondary"
                        ? ["text", "text", "white"]
                        : "white",
                    fontWeight: "bold",
                    mb: theme => theme.spacing.vertical.md,
                  }}
                  variant="h4"
                >
                  {tagline}
                </Text>
              )}
              {title && (
                <Text
                  customStyle={{
                    color:
                      type === "secondary"
                        ? ["text", "text", "white"]
                        : "white",
                    fontSize: [6, 6, 7],
                    maxWidth: textWidth || "none",
                  }}
                  type="h2"
                  variant="h1"
                >
                  {renderLineBreak(title)}
                </Text>
              )}
              {body && (
                <Text
                  customStyle={{
                    color:
                      type === "secondary"
                        ? ["text", "text", "white"]
                        : "white",
                    maxWidth: textWidth || "none",
                    mt: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.md,
                    ],
                    br: {
                      display: ["none", "none", "initial"],
                    },
                  }}
                >
                  {renderLineBreak(body)}
                </Text>
              )}
              {ctaList && (
                <ul
                  sx={{
                    display: "flex",
                    justifyContent: horizontalAlign || null,
                    listStyle: "none",
                    m: 0,
                    mt: 3,
                    p: 0,
                  }}
                >
                  {ctaList.map(ctaData => (
                    <li
                      key={kebabCase(ctaData.text)}
                      sx={{ m: 0, mr: 3, ":last-child": { mr: 0 } }}
                    >
                      <CTA link={ctaData.link || "/"} type={ctaData.type}>
                        {ctaData.text}
                      </CTA>
                    </li>
                  ))}
                </ul>
              )}
            </Column>
          </Row>
        </Container>
      )}
    </Flex>
  )
}

Hero.propTypes = {
  background: PropTypes.shape({
    type: PropTypes.string,
    source: PropTypes.oneOfType([
      PropTypes.shape({
        mobileImage: PropTypes.shape({
          file: PropTypes.shape({ url: PropTypes.string }),
          title: PropTypes.string,
        }),
        desktopImage: PropTypes.shape({
          file: PropTypes.shape({ url: PropTypes.string.isRequired }),
          title: PropTypes.string,
        }),
      }),
      PropTypes.string,
    ]).isRequired,
  }),
  body: PropTypes.string,
  ctaList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      link: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  customStyle: PropTypes.object,
  horizontalAlign: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  textWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  verticalAlign: PropTypes.string,
}

export default Hero
