/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"

import PageLink from "./page-link"
import Text from "./text"

const ProjectCard = ({ background, link, text, type, ...restProps }) => {
  const componentType = type || "primary"
  const styleList = {
    height: {
      primary: 385,
      secondary: 665,
      tertiary: [210, 210, 360],
    },
  }
  const renderContent = () =>
    text ? (
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          position: "absolute",
          height: "100%",
          p: [3, 3, 4],
        }}
      >
        {text.map(textContent => (
          <Text
            customStyle={{
              color: "white",
              fontWeight: "heading",
              lineHeight: 1.25,
              textShadow: "1px 1px #666666",
            }}
            key={kebabCase(textContent)}
          >
            {textContent}
          </Text>
        ))}
      </div>
    ) : null

  return (
    <div
      sx={{
        ...(background && {
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
        }),
        borderRadius: 5,
        display: "flex",
        height: styleList.height[componentType],
        overflow: "hidden",
      }}
      {...restProps}
    >
      {link ? (
        <PageLink
          customStyle={{
            display: "flex",
            height: "100%",
            width: "100%",
          }}
          link={link.to}
          type={link.type}
        >
          {renderContent()}
        </PageLink>
      ) : (
        renderContent()
      )}
    </div>
  )
}

ProjectCard.propTypes = {
  background: PropTypes.string,
  link: PropTypes.shape({
    to: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  text: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
}

export default ProjectCard
