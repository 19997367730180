/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

import { CarouselContext } from "./context"

const CarouselPagination = ({
  containerStyle,
  trigger: TriggerTagName,
  triggerStyle,
}) => {
  const { totalSlides, activeIndex, setActiveIndex } = React.useContext(
    CarouselContext
  )

  return totalSlides > 1 ? (
    <ul
      sx={{
        display: "flex",
        listStyle: "none",
        m: 0,
        ...containerStyle,
      }}
    >
      {[...Array(totalSlides).keys()].map(index => {
        return (
          <li sx={triggerStyle} key={index}>
            <TriggerTagName
              active={activeIndex === index}
              onClick={() => {
                setActiveIndex(index)
              }}
            />
          </li>
        )
      })}
    </ul>
  ) : null
}

const CarouselTrigger = props => {
  const { active, ...restProps } = props
  const triggerSize = [5, 5, 12]

  return (
    <button
      sx={{
        background: "none",
        border: 0,
        cursor: "pointer",
        height: triggerSize,
        p: 0,
        width: triggerSize,
      }}
      type="button"
      {...restProps}
    >
      <span
        sx={{
          backgroundColor: "muted",
          borderRadius: "100%",
          display: "block",
          height: triggerSize,
          width: triggerSize,
          ...(active && {
            backgroundColor: "primary",
          }),
        }}
      />
    </button>
  )
}

export { CarouselPagination, CarouselTrigger }
