/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

import { useCarouselNavigation } from "./hooks"

export const CarouselNavigationButton = props => {
  const { customStyle, ...restProps } = props

  return (
    <button
      sx={{
        background: "none",
        border: "none",
        cursor: "pointer",
        fontSize: 5,
        p: 0,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        ...customStyle,
      }}
      {...restProps}
    />
  )
}

export const CarouselNavigation = ({
  nextControllerStyle,
  previousControllerStyle,
}) => {
  const carouselNavigationButtonStyle = {
    backgroundColor: theme => theme.colors.backgrounds.primary,
    borderRadius: "50%",
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.43)",
    color: "text",
    height: 40,
    width: 40,
  }
  const {
    onClickNext,
    onClickPrevious,
    showNext,
    showPrevious,
  } = useCarouselNavigation()

  return (
    <React.Fragment>
      {showPrevious && (
        <CarouselNavigationButton
          customStyle={{
            ...carouselNavigationButtonStyle,
            left: "5%",
            ...previousControllerStyle,
          }}
          onClick={onClickPrevious}
        >
          {"<"}
        </CarouselNavigationButton>
      )}

      {showNext && (
        <CarouselNavigationButton
          customStyle={{
            ...carouselNavigationButtonStyle,
            right: "5%",
            ...nextControllerStyle,
          }}
          onClick={onClickNext}
        >
          {">"}
        </CarouselNavigationButton>
      )}
    </React.Fragment>
  )
}
