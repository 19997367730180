/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"

import { CarouselContext } from "./common/context"
import { CarouselPagination, CarouselTrigger } from "./common/pagination"

import theme from "../../gatsby-plugin-theme-ui"

const CarouselPrimary = ({ children }) => {
  const { activeIndex, setActiveIndex } = React.useContext(CarouselContext)

  return (
    <React.Fragment>
      <SwipeableViews
        enableMouseEvents
        index={activeIndex}
        onChangeIndex={setActiveIndex}
        axis="y"
        containerStyle={{
          height: `calc(100vh - ${theme.sizes.header.height}px)`,
          minHeight: 568,
        }}
      >
        {children}
      </SwipeableViews>

      <CarouselPagination
        containerStyle={{
          flexDirection: "column",
          position: "absolute",
          right: 5,
          top: "50%",
          transform: "translateY(-50%)",
        }}
        triggerStyle={{
          lineHeight: 1,
          mb: 20,
          ":last-child": { mb: 0 },
        }}
        trigger={CarouselTrigger}
      />
    </React.Fragment>
  )
}

CarouselPrimary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CarouselPrimary
