/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"

import { CarouselContext } from "./common/context"
import { CarouselNavigation } from "./common/controls"

const CarouselTertiary = ({ children }) => {
  const { activeIndex, setActiveIndex } = React.useContext(CarouselContext)
  const navigationStyle = {
    boxShadow: "none",
  }

  return (
    <div sx={{ position: "relative" }}>
      <SwipeableViews
        enableMouseEvents
        index={activeIndex}
        onChangeIndex={setActiveIndex}
      >
        {children}
      </SwipeableViews>

      <CarouselNavigation
        previousControllerStyle={{
          ...navigationStyle,
        }}
        nextControllerStyle={{
          ...navigationStyle,
        }}
      />
    </div>
  )
}

CarouselTertiary.propTypes = { children: PropTypes.node.isRequired }

export default CarouselTertiary
